@import "~@blueprintjs/core/lib/scss/variables";

$cobalt4: #4580e6;

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  // https://github.com/tailwindlabs/tailwindcss/issues/8961
  button,
  [role="button"] {
    cursor: default;
  }

  @font-face {
    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Arial.ttf") format("truetype");
  }

  @font-face {
    font-family: "Arial";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Arial Bold Italic.ttf") format("truetype");
  }

  @font-face {
    font-family: "Arial";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Arial Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Arial";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Arial Italic.ttf") format("truetype");
  }

  @font-face {
    font-family: "Calibri";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Calibri.ttf") format("truetype");
  }

  @font-face {
    font-family: "Calibri";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Calibri Bold Italic.ttf") format("truetype");
  }

  @font-face {
    font-family: "Calibri";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Calibri Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Calibri";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Calibri Italic.ttf") format("truetype");
  }

  @font-face {
    font-family: "Calibri";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Calibri.ttf") format("truetype");
  }

  /* Dancing Script */
  @font-face {
    font-family: "Dancing Script";
    src: url("../fonts/DancingScript-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Helvetica";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Helvetica Bold Italic.ttf") format("truetype");
  }

  @font-face {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Helvetica Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Helvetica";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Helvetica Italic.ttf") format("truetype");
  }

  @font-face {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Helvetica.ttf") format("truetype");
  }

  @font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Open Sans Bold Italic.ttf") format("truetype");
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Open Sans Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Open Sans Italic.ttf") format("truetype");
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Open Sans.ttf") format("truetype");
  }

  @font-face {
    font-family: "Times New Roman";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Times New Roman Bold Italic.ttf") format("truetype");
  }

  @font-face {
    font-family: "Times New Roman";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Times New Roman Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Times New Roman";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Times\ New\ Roman\ Italic.ttf") format("truetype");
  }

  @font-face {
    font-family: "Times New Roman";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Times New Roman.ttf") format("truetype");
  }
}

// ANIMATION
.animation-delay-100 {
  animation-delay: 100ms;
}
.animation-delay-200 {
  animation-delay: 200ms;
}
.animation-delay-300 {
  animation-delay: 300ms;
}
.animation-delay-400 {
  animation-delay: 400ms;
}
.animation-delay-500 {
  animation-delay: 500ms;
}

// ASPECT RATIO
.aspect-ratio-page {
  aspect-ratio: 8.5 / 11;
}

// BACKGROUND COLOR
.bg-white,
.hover\:bg-white:hover {
  background-color: white;
}
.bg-gray-100,
.hover\:bg-gray-100:hover {
  background-color: #f7fafc;
}
.bg-gray-200,
.hover\:bg-gray-200:hover {
  // important added because tailwind overrides blueprint
  background-color: #edf2f7 !important;
}

// Blueprint/core colors
.bg-cobalt-4,
.hover\:bg-cobalt-4:hover {
  background-color: $cobalt4;
}
.bg-blue-7,
.hover\:bg-blue-7:hover {
  background-color: #3c5d74;
}
.bg-gray-1,
.hover\:bg-gray-1:hover {
  background-color: $gray1;
}
.bg-forest-4,
.hover\:bg-forest-4:hover {
  background-color: $forest4;
}
.bg-light-gray-3,
.hover\:bg-light-gray-3:hover {
  background-color: $light-gray3;
}
.bg-light-gray-4,
.hover\:bg-light-gray-4:hover {
  background-color: $light-gray4;
}
.bg-gold-3,
.hover\:bg-gold-3:hover {
  background-color: $gold3 !important;
}
.bg-gold-4,
.hover\:bg-gold-4:hover {
  background-color: $gold4 !important;
}

// COLOR
.color-white,
.hover\:color-white:hover {
  color: white;
}
.color-forest-4,
.hover\:color-forest-4:hover {
  color: $forest4;
}
.color-gray-1,
.hover\:color-gray-1:hover {
  color: $gray1;
}
.color-red-3,
.hover\:color-red-3:hover {
  color: $red3;
}
.color-light-gray-2,
.hover\:color-light-gray-2:hover {
  color: $light-gray2;
}
.color-light-gray-3,
.hover\:color-light-gray-3:hover {
  color: $light-gray3;
}
.color-light-gray-4,
.hover\:color-light-gray-4:hover {
  color: $light-gray4;
}
// blue-6 because blueprint colors go from 1 - 5 and #5A829E is bot a blueprint color
.color-blue-6,
.hover\:color-blue-6:hover {
  color: #5a829e;
}
.color-blue-7,
.hover\:color-blue-7:hover {
  color: #3c5d74;
}

// TEXT COLOR
.text-black {
  color: #182026;
}
.text-white {
  color: white;
}
.text-gray-1 {
  color: $gray1;
}
.text-dark-gray-5 {
  color: $dark-gray5;
}

.hover\:text-black:hover {
  color: #182026;
}
.hover\:text-white:hover {
  color: white;
}

@keyframes svg-skeleton-glow {
  0% {
    fill: rgba(206, 217, 224, 0.2);
  }
  100% {
    fill: rgba(92, 112, 128, 0.2);
  }
}

.svg-skeleton {
  animation: 1000ms linear infinite alternate svg-skeleton-glow;
}

g.svg-skeleton {
  rect {
    animation: 1000ms linear infinite alternate svg-skeleton-glow;
  }
}

@keyframes sa-ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.sa-animate-ping {
  animation: sa-ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.nd-checkmark:after {
  content: "";
  position: absolute;
  display: block;
  left: 4px;
  width: 5px;
  height: 11px;
  border: solid #194062;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  top: 0px;
}

.sa-progress-bar.bp5-progress-bar {
  height: 4px;
  border-radius: 0px;
  .bp5-progress-meter {
    border-radius: 3px;
  }
}

.cb-build-progress-bar.bp5-progress-bar {
  height: 10px;
  border-radius: 0px;
  .bp5-progress-meter {
    border-radius: 0px;
  }
}

// Overrides for react-phone-number-input/style.css to make it match blueprint input more closely
.PhoneInput {
  appearance: none;
  background: #ffffff;
  display: flex;
  align-items: center;
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle;
}

.PhoneInput--disabled,
.PhoneInput--readOnly {
  --tw-bg-opacity: 1;
  background-color: rgb(243 247 249 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-border-opacity: 1;
  border-color: rgb(243 247 249 / var(--tw-border-opacity, 1));
  color: rgba(95, 107, 124, 0.6);
  cursor: not-allowed;
  resize: none;
}

.PhoneInput:focus-within {
  border-color: rgb(90 130 158 / var(--tw-border-opacity, 1));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.PhoneInput.bp5-intent-danger {
  box-shadow:
    0 0 0 0 rgba(205, 66, 70, 0),
    0 0 0 0 rgba(205, 66, 70, 0),
    inset 0 0 0 1px #cd4246,
    inset 0 0 0 1px rgba(17, 20, 24, 0.2),
    inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.PhoneInput.bp5-intent-danger:focus-within {
  box-shadow:
    inset 0 0 0 1px #cd4246,
    0 0 0 2px rgba(205, 66, 70, 0.3),
    inset 0 1px 1px rgba(17, 20, 24, 0.2);
}

.PhoneInputInput {
  flex: 1;
  min-width: 0;
  height: 26px;
}
