@import "~@blueprintjs/core/lib/scss/variables.scss";

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#__next {
  font-family: "Open Sans", sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #f5f8fa;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h2 {
  font-size: 28px;
  letter-spacing: 1px;
  line-height: 32px;
}

h3 {
  font-size: 24px;
  letter-spacing: 1px;
}

a {
  color: #5a829e;
  text-decoration: none;
}

button:focus {
  outline: none;
}

button.bp5-button,
button.bp5-menu-item {
  cursor: default;
  &:hover {
    cursor: default;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

// Primary Button
.bp5-button.bp5-intent-primary {
  &:active {
    background-color: #26455b;
  }

  &.bp5-active {
    background-color: #26455b;
  }

  &.bp5-minimal {
    color: #5a829e;

    &:active {
      color: #26455b;
    }

    &.bp5-active {
      color: #26455b;
    }
  }
}

.bp5-control-group > :last-child .bp5-input-group {
  border-radius: 0 3px 3px 0;
  margin-right: 0;
}

// Slider
span.bp5-slider-handle {
  border-radius: 10px;
}

// Suggest Select Menu
.bp5-multi-select-popover .bp5-menu,
.bp5-select-popover .bp5-menu {
  max-height: 300px;
  overflow: auto;
  padding: 0.25rem;
}

// Checkboxes
.bp5-control.bp5-checkbox input:checked ~ .bp5-control-indicator:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}

// Styles for checkboxes with non white backgrounds
.reverse-checkbox.bp5-control.bp5-checkbox input ~ .bp5-control-indicator {
  background-color: #eee;
}

.reverse-checkbox.bp5-control.bp5-checkbox.bp5-disabled {
  color: #eee;
  opacity: 0.8;
}

.reverse-checkbox.bp5-control.bp5-checkbox
  input:focus
  ~ .bp5-control-indicator {
  outline-color: #eee;
}

.reverse-checkbox.bp5-control.bp5-checkbox
  input:disabled
  ~ .bp5-control-indicator {
  background-color: #d6d6d6;
}

.reverse-checkbox.bp5-control.bp5-checkbox
  input
  ~ .bp5-control-indicator:hover {
  background-color: #d6d6d6;
}

.reverse-checkbox.bp5-control.bp5-checkbox
  input:checked
  ~ .bp5-control-indicator:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='rgba(90,130,158,1)'/%3E%3C/svg%3E");
}

.bp5-toast-container.fixed {
  position: fixed !important;
}

.bp5-input:focus,
.bp5-input.bp5-active {
  box-shadow: inset 0 0 0 1px #5a829e;
}

.bp5-input.bp5-intent-danger:focus {
  box-shadow: inset 0 0 0 1px #cd4246c0;
}

.bp5-input-group.bp5-intent-danger .bp5-input:focus {
  box-shadow:
    inset 0 0 0 1px #cd4246,
    0 0 0 2px rgba(205, 66, 70, 0.3),
    inset 0 1px 1px rgba(17, 20, 24, 0.2);
}

.bp5-input-group.bp5-intent-danger .bp5-input {
  box-shadow:
    0 0 0 0 rgba(205, 66, 70, 0),
    0 0 0 0 rgba(205, 66, 70, 0),
    inset 0 0 0 1px #cd4246,
    inset 0 0 0 1px rgba(17, 20, 24, 0.2),
    inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
