$svg-icon-map: (
  "16px/small-minus.svg":
    "path fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='%23fff'/",
  "16px/small-tick.svg":
    "path fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='%23fff'/",
  "16px/chevron-right.svg":
    "path fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 0 0-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 0 0 1.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%235C7080'/",
  "16px/more.svg":
    "g fill='%235C7080'%3E%3Ccircle cx='2' cy='8.03' r='2'/%3E%3Ccircle cx='14' cy='8.03' r='2'/%3E%3Ccircle cx='8' cy='8.03' r='2'/%3E%3C/g",
);

@function svg-icon($inline-svg, $fill-color) {
  @return url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3C" + map-get(
      $svg-icon-map,
      $inline-svg
    ) + "%3E%3C/svg%3E");
}

@import "~@blueprintjs/core/lib/scss/variables.scss";

//
// Overrides
//

// Typography
$pt-font-family: "Open Sans";
$pt-text-color: #182026;

// Colors
$blue1: #26455b;
$blue2: #3c5d74;
$blue3: #5a829e;
$blue4: #85a4bb;
$blue5: #a5c1d5;
$pt-intent-primary: #5a829e;
$pt-link-color: #5a829e;
$pt-icon-color-selected: #5a829e;
$pt-intent-success: $forest4;
$green1: $forest2;
$green2: $forest3;
$green3: $forest4;
$green4: $forest5;
$green5: #92f79c;

@import "~@blueprintjs/core/src/blueprint.scss";
